@charset "UTF-8";

// ==================================================
//  @use
//  mixin: @include index.sp {~}
//  color: color.$write-main
// ==================================================
@use "mixin" as mixin;
@use "color" as color;

// ==================================================
//  Page Top
// ==================================================
.page-top {
  & + .footer {
    margin-top: 0 !important;
  }

  .main_group {
    @include mixin.sp {
      max-width: 375px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  // Mainvisual
  .mainvisual {
    display: block;
    width: 100%;
    position: relative;

    @include mixin.pc {
      height: auto;
      aspect-ratio: 1/0.578;
      padding: 46px 121px 0 48px;
    }

    @include mixin.sp {
      height: calc(100svh - 57px - 49px);
      margin-top: 57px;
      padding: 0;
      background-color: #ccc;
    }

    &::after {
      content: "";
      display: block;
      width: 39px;
      height: auto;
      aspect-ratio: 1/3.45;
      background-image: url(../img/top/scrollguide.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
      position: absolute;
      bottom: 42px;
      left: 50%;
      transform: translateX(-50%);

      @include mixin.sp {
        width: 30px;
        bottom: 98px;
      }
    }

    &_fixed {
      display: block;
      width: 100%;
      height: 100%;
      padding: 46px 121px 42px 48px;
      position: fixed;
      top: 0;
      left: 0;

      @include mixin.sp {
        height: calc(100% - 57px);
        top: 57px;
        padding: 0;
      }

      &_inner {
        display: block;
        width: 100%;

        @include mixin.pc {
          height: auto;
          aspect-ratio: 1/0.578;
          border-radius: 15px;
          overflow: hidden;
        }

        @include mixin.sp {
          height: 100%;
        }
      }

      &_slider {
        @include mixin.sp {
          height: 100%;
        }

        // Javascript Slick Slider
        .slick-list {
          @include mixin.sp {
            height: 100%;
          }
        }
        .slick-track {
          @include mixin.sp {
            height: 100%;
          }

          img {
            @include mixin.sp {
              object-fit: cover;
            }
          }
        }
      }
    }

    &_news {
      display: none;

      @include mixin.sp {
        display: block;
        width: 100%;
        position: absolute;
        z-index: 2;
        background-color: rgba(0, 0, 0, 0.36);
        bottom: 0;
      }

      &_inner {
        @include mixin.sp {
          display: block;
          text-decoration: none;
          color: color.$white;
          padding: 12px 20px 8px;
        }
      }

      &_date {
        @include mixin.sp {
          display: block;
          width: 86px;
          text-align: center;
          font-size: 1.3rem;
          line-height: calc(22 / 13);
          background-color: color.$site-write;
          padding: 0 4px;
        }
      }

      &_title {
        @include mixin.sp {
          font-size: 1.4rem;
          margin: 5px 0 0;
        }
      }
    }
  }

  // Concept
  .concept {
    display: block;
    width: 100%;
    position: relative;
    background-color: color.$white;
    padding: 71px 0 72px;

    @include mixin.sp {
      padding: 66px 0;
    }

    // Main Group
    .main_group {
      margin-top: 0;

      &_title_text {
        @include mixin.pc {
          transform: translateX(56px);
        }
      }
      &_content {
        position: relative;
      }
    }

    &_text {
      display: block;
      width: 100%;
      max-width: 498px;
      background-color: #fbf9f7;
      margin-top: 424px;
      position: relative;
      z-index: 2;
      transform: translateX(-152px);

      @include mixin.sp {
        max-width: none;
        width: calc(100% + 10px);
        margin-top: 374px;
        transform: translateX(-46px);
      }

      &_title {
        width: calc(100% - 38px);
        font-size: 3.2rem;
        line-height: calc(43 / 32);
        margin: 0;
        padding: 71px 26px 31px 54px;
        border-bottom: 1px solid color.$site-write;

        @include mixin.sp {
          width: calc(100% - 23px);
          font-size: 2.4rem;
          line-height: calc(32 / 24);
          padding: 47px 22px 26px;
        }
      }

      &_inner {
        display: block;
        width: 100%;
        padding: 31px 20px 70px 56px;
        @include mixin.content-rule;

        @include mixin.sp {
          padding: 25px 23px 39px;
        }
      }

      &_paragraph {
        font-size: 1.6rem;
        line-height: calc(26 / 16);
        margin: 40px 0 0;

        @include mixin.sp {
          font-size: 1.5rem;
          line-height: calc(26 / 15);
          margin-top: 26px;
        }
      }
    }

    &_image {
      display: block;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;

      &_item {
        display: block;
        width: 100%;

        // Modifier
        &-01 {
          max-width: 652px;
          margin: 37px 36px 0 0;

          @include mixin.sp {
            max-width: 294px;
            margin: 0;
          }
        }
        &-02 {
          max-width: 417px;
          margin: -43px -20px 0 auto;

          @include mixin.sp {
            max-width: 226px;
            margin: 17px 20px 0 auto;
          }
        }
        &-03 {
          max-width: 417px;
          margin: 51px -20px 0 auto;

          @include mixin.sp {
            display: none;
          }
        }
      }
    }
  }

  // Service
  .service {
    display: block;
    width: 100%;
    position: relative;
    background-color: color.$white;
    padding: 71px 0 165px;
    margin-top: 365px;

    @include mixin.sp {
      padding: 66px 0 46px;
      margin-top: 172px;
    }

    // Main Group
    .main_group {
      margin-top: 0;
      flex-direction: row-reverse;
      padding-left: 0;
      padding-right: 60px;

      @include mixin.sp {
        padding-left: 0;
        padding-right: 15px;
      }

      &_title_text {
        margin-left: auto;
      }
      &_content {
        position: relative;
      }
    }

    &_text {
      display: block;
      width: 100%;
      max-width: 498px;
      background-color: #fbf9f7;
      margin-top: 631px;
      margin-left: auto;
      transform: translateX(232px);
      position: relative;
      z-index: 2;

      @include mixin.sp {
        max-width: none;
        width: calc(100% + 10px);
        margin-top: 371px;
        transform: translateX(36px);
      }

      &_title {
        width: calc(100% - 38px);
        font-size: 3.2rem;
        line-height: calc(43 / 32);
        margin: 0;
        padding: 71px 26px 31px 54px;
        border-bottom: 1px solid color.$site-write;

        @include mixin.sp {
          width: calc(100% - 23px);
          font-size: 2.4rem;
          line-height: calc(32 / 24);
          padding: 47px 22px 26px;
        }
      }

      &_inner {
        display: block;
        width: 100%;
        padding: 31px 20px 70px 56px;
        @include mixin.content-rule;

        @include mixin.sp {
          padding: 25px 23px 39px;
        }
      }

      &_paragraph {
        font-size: 1.6rem;
        line-height: calc(26 / 16);
        margin: 40px 0 0;

        @include mixin.sp {
          font-size: 1.5rem;
          line-height: calc(26 / 15);
          margin-top: 26px;
        }
      }

      &_link {
        display: block;
        width: 100%;
        max-width: 249px;
        background-color: color.$site-main;
        color: color.$white;
        font-size: 1.6rem;
        line-height: 1;
        padding: 18px 0;
        margin-top: 43px;
        text-decoration: none;
        @include mixin.hoverOpacity60;

        @include mixin.sp {
          margin-top: 30px;
        }

        &::before {
          content: "";
          display: inline-block;
          vertical-align: middle;
          width: 147px;
          height: 1px;
          background-color: color.$white;
          transform: translateY(-1px);
          margin-right: 18px;
        }
      }
    }

    &_image {
      display: block;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 60px;

      @include mixin.sp {
        left: 0;
      }

      &_item {
        display: block;
        width: 100%;

        // Modifier
        &-01 {
          max-width: 652px;
          margin: 264px 0 0 0;

          @include mixin.sp {
            max-width: 294px;
            margin-top: 0;
          }
        }
        &-02 {
          max-width: 417px;
          margin: 42px 0 0 0;

          @include mixin.sp {
            max-width: 218px;
            margin: 17px 0 0 20px;
          }
        }
      }
    }
  }

  // Staff
  .staff {
    display: block;
    width: 100%;
    position: relative;
    background-color: color.$white;
    padding: 71px 0 140px;
    margin-top: 402px;

    @include mixin.sp {
      padding: 66px 0 0;
      margin-top: 180px;
    }

    // Main Group
    .main_group {
      margin-top: 0;

      &_title_text {
        @include mixin.pc {
          margin-left: 63px;
        }
      }
    }

    &_list {
      display: block;
      width: calc((100vw - 1040px) / 2 + 100% + 152px);
      max-width: 1160px;
      transform: translateX(-152px);
      padding: 55px 0 57px 68px;
      margin-top: 252px;
      z-index: 0;

      @include mixin.sp {
        width: calc(100% + 82px);
        transform: translateX(-82px);
        margin-top: 129px;
        padding: 36px 0 32px;
      }

      &::before {
        content: "";
        display: block;
        width: calc((100vw - 1040px) / 2 + 100% + 152px);
        height: 100%;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        background-color: #fbf9f7;

        @include mixin.sp {
          width: 100%;
        }
      }

      &_inner {
        @include mixin.pc {
          display: flex;
          flex-wrap: wrap;
          column-gap: 24px;
          row-gap: 24px;
          width: 100%;
          max-width: 911px;
        }

        // Javascript Slick Slider
        &.slick-slider {
          .slick-slide {
            @include mixin.sp {
              margin: 0 15px;
            }
          }
        }
      }

      &_item {
        display: block;
        width: calc((100% / 4) - (72px / 4));
        text-decoration: none;
        background-color: color.$white;
        padding: 17px 16px 15px;
        @include mixin.hoverOpacity60;

        @include mixin.sp {
          width: auto;
          padding: 19px;
        }

        &_image {
          display: block;
          width: 100%;
          height: auto;
          aspect-ratio: 1/0.753;

          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &_grade {
          display: block;
          font-size: 1.3rem;
          border-bottom: 1px solid color.$site-write;
          padding: 9px 0 8px;

          @include mixin.sp {
            font-size: 1.5rem;
            padding: 11px 0 10px;
          }
        }

        &_name {
          font-size: 1.7rem;
          margin: 9px 0 0;

          @include mixin.sp {
            font-size: 1.9rem;
          }
        }

        &_nameEN {
          display: block;
          font-size: 1.3rem;
          margin: 3px 0 0;

          @include mixin.sp {
            font-size: 1.5rem;
          }
        }
      }
    }
  }

  // Information
  .information {
    display: block;
    width: 100%;
    position: relative;
    background-color: color.$white;
    padding: 71px 0 53px;
    margin-top: 376px;

    @include mixin.sp {
      display: none;
    }

    // Main Group
    .main_group {
      margin-top: 0;
      flex-direction: row-reverse;
      padding-left: 0;
      padding-right: 60px;

      &_title_text {
        margin-left: auto;
      }
      &_content {
        position: relative;
        padding-right: 73px;
      }
    }

    &_list {
      display: block;
      padding: 33px 35px 35px 60px;
      margin-top: 93px;
      position: relative;
      z-index: 0;

      &::before {
        content: "";
        display: block;
        width: calc((100vw - 1040px) / 2 + 100% + 152px);
        height: 100%;
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        background-color: #fbf9f7;
      }

      &_item {
        display: flex;
        align-items: flex-start;
        column-gap: 8px;
        text-decoration: none;
        width: 100%;
        margin-top: 25px;
        @include mixin.hoverOpacity60;

        &:first-of-type {
          margin-top: 0;
        }

        &_date {
          display: block;
          font-size: 1.3rem;
          line-height: 1;
          padding: 4px 10px;
          color: color.$white;
          background-color: color.$site-write;
        }

        &_title {
          font-size: 1.4rem;
          margin: 0;
        }
      }
    }

    &_link {
      display: block;
      width: 100%;
      max-width: 249px;
      background-color: color.$site-main;
      color: color.$white;
      font-size: 1.6rem;
      line-height: 1;
      padding: 18px 0;
      margin: 23px 106px 0 auto;
      text-decoration: none;
      @include mixin.hoverOpacity60;

      &::before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 147px;
        height: 1px;
        background-color: color.$white;
        transform: translateY(-1px);
        margin-right: 18px;
      }
    }
  }

  .instagram {
    display: block;
    width: 100%;
    padding: 0 0 106px;
    position: relative;
    background-color: color.$white;

    @include mixin.sp {
      padding: 80px 0;
    }

    &_title {
      @include mixin.font-yumincho;
      font-size: 3rem;
      font-weight: 900;
      line-height: 1;
      margin: 0;
      text-align: center;

      @include mixin.sp {
        font-size: 2.2rem;
      }

      &::after {
        content: "";
        display: block;
        width: 50px;
        height: auto;
        aspect-ratio: 1/0.577;
        background-image: url(../img/common/ico_symbol.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% auto;
        margin: 22px auto 0;

        @include mixin.sp {
          margin-top: 24px;
        }
      }
    }

    &_feed {
      display: block;
      width: 100%;
      max-width: 1080px;
      height: auto;
      margin: 45px auto 0;

      @include mixin.sp {
        width: calc(100% - 72px);
        margin-top: 32px;
      }
    }

    &_link {
      display: block;
      width: 100%;
      max-width: 249px;
      background-color: color.$site-main;
      color: color.$white;
      font-size: 1.6rem;
      line-height: 1;
      padding: 18px 0;
      margin: 66px auto 0;
      text-decoration: none;
      @include mixin.hoverOpacity60;

      &::before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 88px;
        height: 1px;
        background-color: color.$white;
        transform: translateY(-1px);
        margin-right: 18px;
      }
    }
  }

  // Modal Staff
  .modal_staff {
    &_content {
      display: flex;
      align-items: center;

      @include mixin.sp {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 20px;
        max-width: 350px;
        margin: 0 auto;
      }

      &_image {
        display: block;
        width: 100%;
        max-width: 594px;
        height: auto;
        aspect-ratio: 1/0.68;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &_text {
        display: block;
        width: calc(100% - 594px);
        color: color.$white;
        padding-left: 39px;

        @include mixin.sp {
          width: 100%;
          padding-left: 0;
        }

        &_grade {
          display: block;
          font-size: 1.3rem;
          border-bottom: 1px solid color.$white;
          padding-left: 39px;
          width: calc(100% + 39px);
          margin-left: -39px;
          padding-bottom: 8px;

          @include mixin.sp {
            width: 100%;
            font-size: 1.4rem;
            margin: 0;
            padding: 0 0 12px;
          }

          &::before {
            content: "";
            display: inline-block;
            vertical-align: middle;
            width: 36px;
            height: auto;
            aspect-ratio: 1/0.577;
            background-image: url(../img/common/ico_symbol_w.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100% auto;
            transform: translateY(-2px);
            margin-right: 10px;

            @include mixin.sp {
              margin-right: 12px;
            }
          }
        }

        &_name {
          font-size: 2.1rem;
          margin: 8px 0 0;

          @include mixin.sp {
            font-size: 2.2rem;
          }
        }

        &_nameEN {
          display: block;
          font-size: 1.2rem;
          line-height: 1;
          margin: 8px 0 0;

          @include mixin.sp {
            font-size: 1.1rem;
          }
        }

        &_message {
          font-size: 1.2rem;
          line-height: calc(18 / 12);
          margin: 22px 0 0;

          @include mixin.sp {
            font-size: 1.2rem;
            margin-top: 16px;
            line-height: calc(21 / 13);
          }
        }
      }
    }
  }
}
